<template>
<div class="container" style="max-width: 1500px">
<footer class="site-footer has-reynolds-400-background-color focus-within">
  <div class="site-footer__container focus-within">
    <div class="site-footer__header focus-within">
      <a href="https://giving.ncsu.edu" class="site-footer__lockup focus-within">
        <div class="site-footer__brick">
          <svg aria-label="NC State" role="img" viewBox="0 0 54 17.1"><path d="M0,13.5v-10h2.8l2.6,6.9V3.5h2.1v10H4.8L2.1,6.1v7.4C2.1,13.6,0,13.6,0,13.5z M9.2,8.5c0-2.6,0-5.2,3.5-5.2
	c2.1,0,3,1.3,2.8,3.4h-2.1c0-1.3-0.1-1.8-0.8-1.8c-1.3,0-1.3,1-1.3,3.6s0.1,3.8,1.3,3.8c0.9,0,0.9-1.3,0.9-2.1h2.1
	c0,2.6-1,3.5-3,3.5C9.2,13.6,9.2,11,9.2,8.5z M22.8,13.6c-2.6,0-3.1-1.4-3-3.2H22c0,1,0,1.8,1.3,1.8c0.6-0.1,1-0.6,0.9-1.1l0,0
	c0-1.8-4.1-1.9-4.1-4.9c0-1.6,0.6-2.8,3.2-2.8c2.1,0,3.1,0.9,3,3.1h-2.1c0-0.8-0.1-1.6-1-1.6c-0.6,0-1,0.5-1,1
	c0,1.9,4.1,1.8,4.1,4.9C26.3,13.4,24.5,13.6,22.8,13.6z M33.8,3.5v1.7h-2.3v8.4h-2.1V5.2h-2.3V3.5H33.8z M33.2,13.5l2.8-10h2.7
	l2.7,10h-2.2l-0.5-2.2h-2.8l-0.6,2.2H33.2z M37.2,5.5l-0.9,4.3h1.9L37.2,5.5z M47.5,3.5v1.7h-2.2v8.4h-2.1V5.2h-2.3V3.5H47.5z
	 M48.6,13.5v-10H54v1.4h-3.5v2.6h3.1v1.6h-3.1v3H54v1.4H48.6z" style="fill:#FFF"></path></svg>

        </div>
                <span class="sr-only">Home</span>
      </a>
    </div>

    <div class="site-footer__contact">
      <p>Campus Box 7474<br>
Raleigh, NC 27695-7474<br>
919-515-7827<br>
<a href="mailto:donor-relations@ncsu.edu">Email Us</a></p>


      
          </div>

          <div class="site-footer__user-engagement-area site-footer__map">
    <h2 class="site-footer__subhead">Campus Map</h2>
    <a href="https://maps.ncsu.edu/">
      <img width="300" height="169" src="https://giving.ncsu.edu/wp-content/uploads/sites/4/2022/10/campus-map-300x169.jpeg" class="attachment-medium size-medium" alt="Campus Map" decoding="async" srcset="https://giving.ncsu.edu/wp-content/uploads/sites/4/2022/10/campus-map-300x169.jpeg 300w, https://giving.ncsu.edu/wp-content/uploads/sites/4/2022/10/campus-map.jpeg 525w" sizes="(max-width: 300px) 100vw, 300px">
    </a>
  </div>


    
    <div class="site-footer__links">
    <ul id="menu-footer-menu" class="menu"><li id="menu-item-26084" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26084"><a href="https://accessibility.ncsu.edu/">Accessibility</a></li>
<li id="menu-item-26085" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26085"><a href="https://advancement-services.ncsu.edu/">Advancement Services</a></li>
<li id="menu-item-26086" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26086"><a href="https://news.giving.ncsu.edu/">Giving News</a></li>
<li id="menu-item-26093" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26093"><a href="https://giving.ncsu.edu/how-to-give/give-today/">Make a Gift</a></li>
<li id="menu-item-26087" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26087"><a href="https://www.ncsu.edu/">NC&nbsp;State Website</a></li>
<li id="menu-item-26096" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26096"><a href="https://giving.ncsu.edu/foundation/">NC&nbsp;State University Foundation</a></li>
<li id="menu-item-26088" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26088"><a href="https://policies.ncsu.edu/">Policies</a></li>
<li id="menu-item-26089" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26089"><a href="https://leadership.ncsu.edu/advancement/privacy-statement/">Privacy Statement</a></li>
<li id="menu-item-26097" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26097"><a href="https://giving.ncsu.edu/connect/share-your-story/">Share Your Story</a></li>
<li id="menu-item-26090" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26090"><a href="https://strategicplan.ncsu.edu/">Strategic Plan</a></li>
<li id="menu-item-26091" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26091"><a href="https://advancement-services.ncsu.edu/biographical-update-form/">Update Your Information</a></li>
<li id="menu-item-26092" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-26092"><a href="https://leadership.ncsu.edu/advancement/">University Advancement</a></li>
</ul>
  </div>

    <div class="site-footer__connect">
    <h2 class="site-footer__subhead">Connect With Us</h2>
    <ul class="site-footer__social-channels" aria-label="Social Media channels">

              <li>
          <a href="https://www.facebook.com/ncstate" id="site-footer__social-facebook" data-ua-cat="Footer" data-ua-action="Social Channel Click" data-ua-label="Facebook">
              <svg class="wolficon wolficon-facebook" role="img" aria-labelledby="wolficon-label-667b15ac2dc46">
            <title id="wolficon-label-667b15ac2dc46">Facebook</title>
            <use xlink:href="#wolficon-facebook">
        </use></svg>
          </a>
        </li>
      
              <li>
          <a href="https://www.twitter.com/ncstate" id="site-footer__social-twitter" data-ua-cat="Footer" data-ua-action="Social Channel Click" data-ua-label="X">
              <svg class="wolficon wolficon-twitter" role="img" aria-labelledby="wolficon-label-667b15ac2dc4c">
            <title id="wolficon-label-667b15ac2dc4c">X</title>
            <use xlink:href="#wolficon-twitter">
        </use></svg>
          </a>
        </li>
      
              <li>
          <a href="https://www.linkedin.com/school/north-carolina-state-university/" id="site-footer__social-linkedin" data-ua-cat="Footer" data-ua-action="Social Channel Click" data-ua-label="LinkedIn">
            <svg class="wolficon wolficon-linkedin" role="img" aria-labelledby="wolficon-label-667b15ac2dc50">
            <title id="wolficon-label-667b15ac2dc50">LinkedIn</title>
            <use xlink:href="#wolficon-linkedin">
        </use></svg>
          </a>
        </li>
      
              <li>
          <a href="https://www.instagram.com/ncstate" id="site-footer__social-instagram" data-ua-cat="Footer" data-ua-action="Social Channel Click" data-ua-label="Instagram">
            <svg class="wolficon wolficon-instagram" role="img" aria-labelledby="wolficon-label-667b15ac2dc52">
            <title id="wolficon-label-667b15ac2dc52">Instagram</title>
            <use xlink:href="#wolficon-instagram">
        </use></svg>
          </a>
        </li>
      
              <li>
          <a href="https://www.youtube.com/NCState" id="site-footer__social-youtube" data-ua-cat="Footer" data-ua-action="Social Channel Click" data-ua-label="YouTube">
          <svg class="wolficon wolficon-youtube" role="img" aria-labelledby="wolficon-label-667b15ac2dc54">
            <title id="wolficon-label-667b15ac2dc54">YouTube</title>
            <use xlink:href="#wolficon-youtube">
        </use></svg>
          </a>
        </li>
      
    </ul>
  </div>

    <div class="site-footer__copyright">
  © 2024 NC State University. All rights reserved.
</div>

  </div>
</footer>
</div>
</template>
<style scoped lang="scss" src="@/assets/css/foot.scss"></style>