<template>
    <div class="container-fluid">
        <heading></heading>
        <div class="container">      
            <div class="mt-5 pt-5 mb-5 pb-5" id="redirecting" v-if="redirecting">
                <h1><i class="fad fa-circle-notch fa-spin"></i> Redirecting you to our giving page...</h1>
            </div>
            <div style="margin-top: 70px" v-else>
                <div class="row mt-2 mb-3">
                    <!-- Grow endowment header -->
                    <div v-if="site == 'growendowment'" class="col">
                        <h1 class="pb-3 mb-2 border-bottom">
                            <i class="fad fa-hand-holding-seedling"></i> Grow my endowment
                        </h1>
                        <div class="pt-2">
                            <p>Search for your endowment below and click "Add to cart". Please call 919.513.9238 if you have any questions or need assistance.</p>
                        </div>
                        <div v-if="hasId && !hasPledges && !loading" class="alert alert-warning">
                            <h4><i class="fad fa-fw fa-exclamation-triangle"></i> We couldn't find any endowments associated with this donor.</h4>
                            <span>If you'd like to give to another NC State fund, you can search our available funds below.</span>
                        </div>
                    </div>
                    <!-- Pay pledge header -->
                    <div v-if="site == 'paypledge'" class="col">
                        <h1 class="pb-3 mb-2 border-bottom">
                            <i class="fad fa-donate"></i> Pay a pledge
                        </h1>
                        <div class="pt-2">
                            <p>Search for a fund you've pledged towards below.</p>
                            <p>If you'd like to also donate to other areas within NC State, you can search our other available funds.</p>
                        </div>
                        <div v-if="hasId && !hasPledges && !loading" class="alert alert-warning">
                            <h4>
                                <i class="fad fa-fw fa-exclamation-triangle"></i> We couldn't find any pledges associated with this donor.
                            </h4>
                            <span>If you'd like to give to another NC State fund, you can search our available funds below.</span>
                        </div>
                    </div>
                </div>
                
                <div class="row mb-4 grey pt-3">
                    <div class="col">
                        <div class="panel">
                            <h2>Search All Funds</h2>
                            <div class="row">
                                <div class="col pt-2 pb-2">
                                    <allocation-select></allocation-select>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col">
                        <div class="p-1 mb-2 border-bottom">
                            <div class="row align-items-center text-end">
                                <div class="col">
                                    <img class="align-baseline" src="@/assets/images/gift.png" alt="My cart">                                    
                                    {{cart.funds.length}} fund<span v-if="cart.funds.length != 1">s</span> selected
                                    <button type="button" v-if="cartEditEnabled" v-on:click="setEditing(!editing)" class="btn btn-sm btn-red ms-2">
                                        <span v-if="editing"><i class="fas fa-check"></i> Done</span>
                                        <span v-else><i class="fas fa-pencil"></i> Edit cart</span>
                                    </button>      
                                </div>
                            </div>                
                            <div id="cartDetail" class="collapse">                        
                                <ul class="list-group list-group-flush text-end">
                                    <li :key="i" class="list-group-item" v-for="(a, i) in cart.funds">
                                        <span class="me-2">{{a.NCSU_LONG_NAME}}</span>
                                        <button type="button" v-on:click="removeFromCart(a)" class="float-end btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                                    </li>                                   
                                </ul>  
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="row mb-5">
                    <div class="col text-center">
                        <button type="button" :disabled="!checkoutEnabled" v-on:click="checkout()" class="btn btn-continue">
                            <span>Continue</span> <i class="fas fa-chevron-right"></i>
                        </button>     
                    </div>
                </div>                   
            </div>    
        </div>
        <foot></foot>
    </div>
</template>
<script>
const cart = {other: false, otherDesc: '', amount: 0, giftType: 'now', funds: []};
import allocationSelect from './allocation.select.vue';
import heading from '../common/head.vue';
import foot from '../common/foot.vue';
import { Collapse } from 'bootstrap';
export default {
    components: {
        allocationSelect: allocationSelect,
        heading: heading,
        foot: foot
    },
    data() {
        return {
            cartDetail: null,
            loading: false,
            redirecting: false,
            editing: false,
            entityId: '',            
            hasId: false,
            hasPledges: false,          
            otherAllocations: [],            
            params: {},  
            cart: cart,
            site: false,
            siteData: {},
            defaults: {       
                'paypledge': {
                    cookie: 'ncsu-og-paypledge',                    
                    params: {APPEAL_CODE: 'WPLGPAY'},                    
                    dataSrc: 'data/php/payment/donor.pledge.designations.get.php'
                },
                'growendowment': {
                    cookie: 'ncsu-og-growendowment',
                    params: {APPEAL_CODE: 'ESRE'},
                    dataSrc: 'data/php/payment/donor.steward.designations.get.php',
                }
            }
        }
    },
    methods: {
        getData: function() {
            this.site = this.$route.name;            
            this.siteData = this.defaults[this.site];                     
            if (this.$cookies.isKey(this.siteData.cookie)) {
                let c = this.$cookies.get(this.siteData.cookie);
                this.cart = c.cart;                                                               
            }
            this.loading = true;                        
            var promises = [];                             
            
                        
            promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/json/all-allocations.json')); //All allocations                
            //If an entity ID has been passed in via the URL
            var donor_id = '';
            if (this.$route.params.id) { 
                donor_id = this.$route.params.id;                  
            }            
            if (Object.keys(this.$route.query).length) {
                donor_id = Object.keys(this.$route.query)[0];                 
            }
            if (donor_id) {                      
                let pattern = new RegExp('^[0-9]{1,10}$');
                if (pattern.test(donor_id)) {                        
                    this.hasId = true;
                    promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+this.siteData.dataSrc, {params: {'donor_id': donor_id}}));
                }
            }


            Promise.all(promises).then(response => {                              
                for (let i = 0; i < response.length; i++) {
                    if (response[i].data.error) { //All allocations
                        this.$root.setAlert(true, response[i].data.error, 'alert-danger');
                        this.loading = false;
                        return;
                    } 
                }       
                for (let i = 0; i < response[0].data.length; i++) {
                    response[0].data[i].inCart = false;
                }
                
                this.otherAllocations = response[0].data;    
                this.hasPledges = false;                            
                if (this.hasId) { //Another promise should exist
                    if (response[1].data.error) {                            
                        this.$root.setAlert(true, response[1].data.error, 'alert-danger');
                        this.loading = false;
                        return;
                    } else {                                                        
                        if (response[1].data.length > 0) { //One or more allocations exist, redirect                                
                            this.cart.funds = response[1].data;                                
                            this.hasPledges = true;                                
                            this.checkout();                                                                                            
                            return;
                        }                          
                    }
                }
                this.loading = false;  
            });                
        },
        addToCart: function(a) {
            a.inCart = true;
            this.cart.funds.unshift(a);  
            var cookieObj = {cart: this.cart};
            this.$cookies.set(this.siteData.cookie, cookieObj);          
        },
        fundInCart: function(a) {
            var out = false;
            if (this.cart.funds.find(f => f.ALLOCATION_CODE == a.ALLOCATION_CODE)) {
                out = true;
            }
            return out;
        },
        removeFromCart: function(a) {
            a.inCart = false;            
            this.cart.funds = this.cart.funds.filter(c => {
                return a.ALLOCATION_CODE != c.ALLOCATION_CODE;
            })   
            this.otherAllocations.filter(o => {
                return o.ALLOCATION_CODE == a.ALLOCATION_CODE;
            })[0].inCart = false;
            var cookieObj = {cart: this.cart};
            this.$cookies.set(this.siteData.cookie, cookieObj);          
            //If this empties the cart, collapse it
            if (this.cart.funds.length + this.cart.other === 0) {
                this.cartDetail.hide();                    
                this.setEditing(false);                
            }
        },
        setEditing: function(editing) {
            if (editing) {
                this.cartDetail.show();
            } else {                 
                this.cartDetail.hide();
            }
            this.editing = editing;
        },
        checkout: function() {            
            this.redirecting = true;
            window.location.href = this.givingUrl();                
        },
        givingUrl: function() {                       
            var url = 'https://www.givecampus.com/campaigns/47387/donations/new?a=8830926&';            
            if (this.cart.funds.length) {
                for (var i = 0; i < this.cart.funds.length; i++) {
                    var d = 'designation';
                    if (i > 0) {
                        d = '&' + d + '_' + i; //designation_1 through designation_4, separated by ampersands
                    }
                    d = d + '=' + this.cart.funds[i].ALLOCATION_CODE;  
                    url += d;               
                }
            }

            
            return url;    
        }
    },
    computed: {
        checkoutEnabled: function() {
            var out = false;          
            //if (this.cart.funds.length > 0 || this.cart.other === true) {
            if (this.cart.funds.length > 0) {
                out = true;
            }
            return out;
        },
        cartEditEnabled: function() {
            var out = false;
            //if ((this.cart.funds.length > 0 || this.cart.other === true)) {
            if (this.cart.funds.length > 0) {
                out = true;
            }
            return out;
        }
    },
    mounted: function() {
        this.getData();
        this.cartDetail = new Collapse(document.getElementById('cartDetail'), {toggle: false});
    }
}
</script>
<style scoped>
#redirect {
    min-height: 200px;
}
</style>