<template>
    <div class="container">
        <div class="row border-bottom pb-2 mb-2" id="heading">
            <div class="col-12">
                <h1 class="display-5 d-none d-sm-block"><i class="text-danger fad fa-envelope-open-dollar"></i> NC State Employee Payroll Deduction Portal</h1>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h2>The NC State Employee Payroll Deduction Portal is currently unavailable</h2>

                <p>If you would like to add, change or stop a payroll deduction pledge, please contact NC State Gifts and Records Management 
                by phone at 919.515.7827 or by email at <a href='mailto:giftsandrecords@ncsu.edu'>giftsandrecords@ncsu.edu</a> for assistance.</p>
    
            </div>
        </div>
    </div>
</template>

<style scoped>
/*Safari fix */
.row:before, .row:after {
    width:0px; height:0px;
}
.container {
    padding: 60px 0px 60px;
}

h2 {
    font-family: 'Roboto';    
}

h3 {
    font-size: 1.7rem;
    font-family: "Roboto Slab",serif;       
}

.display-5 {
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 1.2;
}

#modalPledgeDelete {
    top: 20%;
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
}

.fade-leave, .fade-enter-to {
    opacity: 1;
    transform: translateX(0);
}

.search-highlight {
    background-color: #ffc107;
    color: #343a40;
}

.radio {
    font-size: 1.2rem;
}

input[type=radio], input[type=checkbox] {    
    transform: scale(1.3);
}

input[type=radio] {    
    margin-top: 0px;
}

input[type=checkbox] {    
    margin-top: 5px;
    margin-right: 10px;
}

.btn {
    border-radius: .25rem;
}

.list-group {
    position: relative;
    display: block;
    padding: 0rem;    
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

#otherAllocations > .list-group {
    border: none;
}

.card.card-default {
    border-color: #ddd;
}

.card-default>.card-header {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card.card-primary {
    border-color: #c00;
}

.card.card-primary > .card-header {    
    background-color: #C00;
}

.cart .card-header h3 {
    color: #FFF;
}

.input-group > input {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.cart .input-group-prepend .input-group-text {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.cart .input-group > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}


.card {
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 1px;    
}
.list-group-item {    
    padding: .75rem 1.25rem;    
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.list-group-item:last-child {
    border-bottom: none;
}

.list-group-item > .btn {
    margin-top: -4px;
}

.list-group.cart-list {
    border: none;
}

.list-group-item.cart-item {
    border-radius: 0;
}

.list-group-item p {
    max-width: 380px;
}

.cart .card-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}

.tooltip-inner {
    font-weight: normal;
}

h4.text-dark {
    font-weight: bold;
}

</style>