//Base components
import { createApp, h } from 'vue';
import App from './app.vue';

//External modules
import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

//Internal modules
import redirect from './components/give/redirect.vue';
import payment from './components/payment/main.vue';
//import payroll from './components/payroll/main.vue';
import payroll from './components/payroll/disabled.vue';
import environment from './components/common/environment.vue';
import alert from './components/common/alert.vue';
import foot from './components/common/foot.vue';

//CSS
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/js/fontawesome.js';
import './assets/css/give.css';
import './assets/css/screen.css';
import './assets/css/webfonts.css';

//jQuery
window.$ = window.jQuery = require('jquery');

const alertDefault = {
  display: false,
  message: '',
  class: 'alert-primary',
  timeout: false
};

//Day of giving
var d = new Date();
var o = 4 - (d.getTimezoneOffset() / 60); //Eastern time offset

//Day of Giving window
var dogStart = new Date('2025', '2', '25', 23 + o, 45, 0); //Date / time + 4 hours for Eastern time UTC offset, use local date to adjust UTC offset
var dogEnd = new Date('2025', '2', '26', 23 + o, 59, 59);

//Red and White week window
var rwStart = new Date('2023', '9', '20', 7 + o, 59, 59);
var rwEnd = new Date('2023', '9', '27', 16 + o, 59, 59);

var gcUrl = 'https://www.givecampus.com/campaigns/47387/donations/';
var dogUrl = 'https://www.givecampus.com/campaigns/47387/donations/'; //This will likely be a separate GC page or a givegab page

//Routes and route components
const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_SRC),
	base: process.env.VUE_APP_BASE_SRC,	
	routes: [		
		{ 
			beforeEnter(to, from, next) { //Remove trailing slash
				var p = to.path;    
				if (to.path != '/' && to.path[to.path.length - 1] === '/') {						
					p = to.path.slice(1, to.path.length - 1).split("/")[1];				
					next({name: 'growendowment', params: {id: p}});		
				} else {
					next();
				}				
        	},
			name: 'growendowment',
			path: '/growendowment/:id?', 
			components: {
				default: payment
			}	
        },		
        { 
			beforeEnter(to, from, next) { //Remove trailing slash
				var p = to.path;    
				if (to.path != '/' && to.path[to.path.length - 1] === '/') {						
					p = to.path.slice(1, to.path.length - 1).split("/")[1];				
					next({name: 'paypledge', params: {id: p}});	
				} else {
					next();
				}			
			},		
			name: 'paypledge',
			path: '/paypledge/:id?', 
			components: {
				default: payment
			}	
		},	
		{
			name: 'encompass',
			path: '/encompass',
			components: {
				default: redirect	
			}
		},
      	{ 
			name: 'payroll',
			path: '/payroll', 
			components: {
				default: payroll
			},
			meta: {
				title: 'NC State University Payroll Deduction Portal'        		
			}	
  		},
      	{
        	name: 'purl',
        	path: '/u/:purlid?',
        	components: {
				default: redirect
			},
      	},
		{
        	name: 'phone',
        	path: '/phone/:id?', 
        	components: {
          		default: redirect
        	}
		},	
		{ 
			name: 'give',
			path: '/:area?', 
			components: {
				default: redirect
			},
			beforeEnter(to, from, next) {
				var p = to.path;    				
				//Force DoG date to be out of window when override appeal is set		
				var appeal = Object.keys(to.query).indexOf('appealcode');		
				if (appeal !== false && (to.query['appealcode'] == "DOG24BACKUP" || to.query['appealcode'] == "DOG24WRDW24" || to.query['appealcode'] == "DOG24PD")) {
					d = new Date('1900', '1', '1', 23 + o, 59, 59);	
				}				
				//If we're within the Day of Giving window, redirect				
          		if (d >= dogStart && d < dogEnd) {              		              		
              		window.location = dogUrl;
				//Red and White week
          		} else if (p == '/redandwhite' && (d < rwStart || d >= rwEnd)) {
					//Redirect users to student giving challenge page if outside of the red and white week challenge window
					var url = 'https://redwhiteweek.ncsu.edu/student-giving-challenge/';
					window.location = url;
				//Go to GiveCampus by default 		            					
				} else {
					next();
				}
        	},
			meta: {
				title: 'Give to NC State',
				metaTags: [
					{name: "description",  content: "At NC State, we power the extraordinary - with your support."},
					{property: "og:title",  content: "Give to NC State"},
					{property: "og:description",  content: "At NC State, we power the extraordinary - with your support."},
					{property: "og:url",  content: "https://give.ncsu.edu/"},
					{property: "og:image",  content: "https://thinkanddo.ncsu.edu/discover/annual-giving/wp-content/uploads/sites/4/2022/03/MAH0061_v1.jpg"},
					{property: "og:type",  content: "website"},
					{name: "twitter:card",  content: "summary_large_image"},
					{name: "twitter:site",  content: "@ncstate"},
					{name: "twitter:creator",  content: "@ncstate"},
					{name: "twitter:title",  content: "Give to NC State"},
					{name: "twitter:description",  content: "At NC State, we power the extraordinary - with your support."},
					{name: "twitter:image",  content: "https://thinkanddo.ncsu.edu/discover/annual-giving/wp-content/uploads/sites/4/2022/03/MAH0061_v1.jpg"}
				]
			}				
		},
		{ 
			name: 'default', 
			path: '/:pathMatch(.*)*',
			redirect: '/'
		}
	]
});


router.beforeEach((to, from, next) => {	

	/*fetch(process.env.BASE_URL + "data/json/site-status.json")
	.then((response) => response.json()).then((status) => {		
		if (status[0].SITE_STATUS == 'down' && to.name != 'default' && from.name != 'error') { 			
			next({name: 'error'});
		}		
	});*/


	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
	const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
	// If a route with a title was found, set the document (page) title to that value.
	if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  
	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
	// Skip rendering meta tags if there are none.
	if(!nearestWithMeta) return next();
  
	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags.map(tagDef => {
		const tag = document.createElement('meta');
  
	  	Object.keys(tagDef).forEach(key => {
			tag.setAttribute(key, tagDef[key]);
	  	});
  
	  	// We use this to track which meta tags we create, so we don't interfere with other ones.
	  	tag.setAttribute('data-vue-router-controlled', '');
  
	  	return tag;
	})
	// Add the meta tags to the document head.
	.forEach(tag => document.head.appendChild(tag));
  
	next();
});

fetch(process.env.BASE_URL + "config.json")
.then((response) => response.json())
.then((config) => {
	const app = createApp({
		data: function() {
			return {
				user: {},
				alert:  JSON.parse(JSON.stringify(alertDefault)),
				env: process.env.VUE_APP_ENV	
			}
		},
		methods: {											
			clearAlert: function() {			
				this.alert = JSON.parse(JSON.stringify(alertDefault));
			},
			currency: function(value) {
				if (value == null) {
					return;
				}
				if (typeof value === 'string' || value instanceof String) {
					value = parseFloat(value);
				}
				return '$'+value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");	
			},			
			highlight: function(item, query) {				
				var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
				var repl = query.replace(invalid, "");
				var check = new RegExp(repl, "ig");
				return item.toString().replace(check, function(matchedText,a,b){
					return ('<span class="bg-warning">' + matchedText + '</span>');
				});				
			},
			
			isLoggedIn: function () {
				const t = window.location.pathname.substring(window.location.pathname.indexOf(this.$config[process.env.VUE_APP_ENV].app.base) + this.$config[process.env.VUE_APP_ENV].app.base.length, window.location.pathname.length);
				const c = this.$config.app_cookie;
				//this.$cookies.remove(c);
				const e = process.env.VUE_APP_ENV;
				if (this.$cookies.isKey(c)) {				
					this.user = this.$cookies.get(c);        
					this.loggedIn = true;
				} else {
					let location = this.$config[process.env.VUE_APP_ENV].uri.data + '/secure/user.data.php?target=' + t;          					        
					window.location = location;            
				}
			},
			setAlert: function(display, message, css) {
				this.alert.display = display;
				this.alert.class = css;
				this.alert.message = message;			
			} 
		},		
		mounted() {			
		},
		render: () => h(App)
	});
	//Global modules
	app.use(router);
	app.use(VueCookies);	
	app.use(VueAxios, axios);
	//Global components
	app.component('alert', alert);
	app.component('environment', environment);
	app.component('foot', foot);
	//Gobal config
	app.config.globalProperties.$config = config;	
	app.mount('#app');
});
	