<template>
  <div id="vue">   
    <alert :alert="this.$root.alert"></alert>    
    <environment :env="this.$root.env"></environment>
    <router-view :user="this.$root.user" name="navigation"></router-view>    
    <router-view></router-view>
    <foot></foot>    
  </div>  
</template>
<script>
export default {
  data() {
    return {      
    }
  }
}
</script>