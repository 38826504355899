<template>
    <div class="row" id="heading">
        <div class="container">
            <div class="row">
                <div class="col-xl-6" id="giving-banner">                             
                    <div>
                        <h1>Giving to NC State</h1>
                        <p>With your investment, we will continue to think beyond boundaries and do the extraordinary.</p>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {         
    data() {
        return {
        
        }
    },
    mounted() {        
        
    }
}
</script>
<style scoped>
@media (max-width: 482px) {
    #heading {
        height: inherit !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

#heading { 
    height: 400px;
    background: url('@/assets/images/bg.jpg') center center no-repeat;     
    padding-top: 225px;
    background-size: cover;
}


#giving-banner {
    background-color: #C00;
    color: #FFF;    
    padding: 20px 20px 5px 20px;    
}

#giving-banner h1 {
    font-family: Roboto,Arial,sans-serif;
    font-weight: bold;
    font-size: 3rem;
    margin: 22px 0px 40px 0px;
}

#giving-banner p {
    font-family: 'Roboto';
    line-height: 1.6em;
    font-size: 1.125rem;   
}

</style>