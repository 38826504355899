<template>
    <div id="env" class="text-center" v-if="env != 'PRD'">
        <div class="alert alert-warning">
           <i class="fas fa-exclamation-triangle"></i> This is the <strong>{{env}}</strong> environment
		    <button type="button" data-bs-dismiss="alert" class="btn-close float-end" aria-label="Close"></button>
        </div>       
    </div>
</template>
<script>
export default {
    props: ['env']
}
</script>
<style type="text/css">
#env {
	/* fixed position a zero-height full width container */
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	top: 0px;
	
	/* center all inline content */
	text-align: center;	
	z-index: 99999;			
}
		
#env > .alert {	
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
	display: inline-block;
	text-align: left;	
}

.blink_text {
/*
animation:1s blinker linear infinite;
-webkit-animation:1s blinker linear infinite;
-moz-animation:1s blinker linear infinite;
color: red;
*/
}

@-moz-keyframes blinker {  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	100% { opacity: 1.0; }
	}

@-webkit-keyframes blinker {  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	100% { opacity: 1.0; }
	}

@keyframes blinker {  
	0% { opacity: 1.0; }
	50% { opacity: 0.0; }
	100% { opacity: 1.0; }
	}
</style>